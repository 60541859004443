import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/MainContent.css';
import profilePic from '../assets/images/me2.jpg';

const MainContent: React.FC = () => {
    return (
        <div className="main-content">
            <div className="profile-image">
                <img src={profilePic} alt="Profile" />
            </div>
            <div className="profile-summary">
                <p>
                    I am a Technical Leader with over 15 years of experience in Software Engineering, Application Design, and Development. Proven success in driving technological innovation, optimizing business processes, and leading cross-functional teams to deliver high-quality software solutions within the financial services sector. Seeking a senior-level position to leverage my expertise in software engineering, team leadership, and agile methodologies.
                </p>
                <div className="profile-buttons">
                    <Link to="/resume" className="button-link">
                        Experience
                    </Link>
                    <Link to="/projects" className="button-link">
                        Projects
                    </Link>
                    {/* <Link to="/contact" className="button-link">
                        Get in Touch
                    </Link> */}
                </div>
            </div>
        </div>
    );
};

export default MainContent;
