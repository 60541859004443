import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import profilePic from '../assets/images/me2.jpg'; // Import the image

const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="header-left">
                <Link to="/" className="profile-pic-link">
                    <img src={profilePic} alt="Profile" className="profile-pic" />
                </Link>
                <div className="text-container">
                    <Link to="/" className="home-link">
                        <h1>Santhosh Janakiraman</h1>
                        <i className="fab fa-linkedin linkedin-icon"></i>
                    </Link>
                    <h2>Tech Lead ( Data Engineering & Applications)</h2>
                </div>
            </div>
            <div className="header-right">
                <nav>
                    <Link to="/">Home</Link>
                    <Link to="/resume">Experience</Link>
                    <Link to="/projects">Projects</Link>
                    {/* <Link to="/contact">Contact</Link> */}
                </nav>
            </div>
        </header>
    );
};

export default Header;
