import React, { useState } from 'react';
import '../styles/ProjectsPage.css';
import projectsData from '../data/projects.json';

const ProjectsPage: React.FC = () => {
    const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);

    const handleProjectClick = (index: number) => {
        setSelectedProjectIndex(index);
    };

    const project = projectsData.projects[selectedProjectIndex];

    return (
        <div className="projects-page">
            <h1>Project Portfolio</h1>
            <div className="projects-container">
                {/* Project Navigation on the left */}
                <div className="projects-nav">
                    <ul>
                        {projectsData.projects.map((project, index) => (
                            <li 
                                key={index} 
                                className={index === selectedProjectIndex ? 'active' : ''}
                                onClick={() => handleProjectClick(index)}
                            >
                                {project.title}
                            </li>
                        ))}
                    </ul>
                </div>
                
                {/* Project Details on the right */}
                <div className="project-details">
                    <h2>{project.title}</h2>
                    <h3>Background</h3>
                    <p>{project.background}</p>
                    <h3>Scope</h3>
                    <ul>
                        {project.scope.map((item, idx) => (
                            <li key={idx}>{item}</li>
                        ))}
                    </ul>
                    <h3>My Role</h3>
                    <ul>
                        {project.role.map((item, idx) => (
                            <li key={idx}>{item}</li>
                        ))}
                    </ul>
                    <h3>Environment</h3>
                    <ul>
                        {project.environment.map((item, idx) => (
                            <li key={idx}>{item}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ProjectsPage;
