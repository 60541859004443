
import React from 'react';
import '../styles/ResumePage.css';
import data from '../data/data.json';

const ResumePage: React.FC = () => {
    return (
        <div className="resume-page">
            <section className="experience-section">
                <h2>Professional Experience</h2>
                {data.professionalExperience.map((experience, index) => (
                    <div key={index} className="experience-item">
                        <div className="experience-timeline">
                            <h3>
                                {experience.startDate} <br /> {experience.endDate}
                            </h3>
                        </div>
                        <div className="experience-details">
                            <h4>{experience.role}</h4>
                            <ul>
                                {experience.description.map((desc, idx) => (
                                    <li key={idx}>{desc}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </section>

            <section className="education-section">
                <h2>Education</h2>
                {data.education.map((edu, index) => (
                    <div key={index} className="education-item">
                        <div className="education-timeline">
                            <h3>
                                {edu.startDate} <br /> {edu.endDate}
                            </h3>
                        </div>
                        <div className="education-details">
                            <h4>{edu.degree}</h4>
                            <p>{edu.institution}</p>
                            {edu.cgpa && <p>{edu.cgpa}</p>}
                        </div>
                    </div>
                ))}
            </section>
        </div>
    );
};

export default ResumePage;
